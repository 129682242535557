import 'focus-visible'
import '@ingka/base/dist/style.css'
import '@ingka/focus/dist/style.css'
import '@ingka/grid/dist/style.css'
import '../globals.css'

import type { AppProps } from 'next/app'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'

import { Layout } from '~/components/layout'
import { Toaster } from '~/components/ui/toaster'
import AppProviders from '~/context/app-providers'

function AsIsShopManager({ Component, pageProps, router }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <AppProviders>
        <Layout locale={router.locale}>
          <Component {...pageProps} />
          <Toaster />

          <div id="image-upload-portal" />
        </Layout>
      </AppProviders>
    </>
  )
}

export default appWithTranslation(AsIsShopManager)
